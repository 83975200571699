.RPL-Category_newImg {
  padding-left: 20px;
  margin-top: 23px;
}
.RPL-Category_subcategory_newImg {
  display: flex;
  align-items: center;
  height: 50%;
  margin-left: 20px;
  margin-bottom: 0;
  padding-right: 8px;
  color: #e86161;
  font-weight: bold;
  border: 1px solid #e86161;
  border-radius: 3px;
}

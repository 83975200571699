@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,100');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#root,
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rpl-MasterLayout__footer {
}

/** TODO: rebuild layout better **/

#rpl-layout {
  background-color: #e5e5e5;
}

#rpl-header {
  display: flex;
  column-gap: 20px;
  padding: 0 20px;
  align-items: center;
}

#rpl-sidebar {
  height: 100vh;
  overflow: hidden;
}

#rpl-sidebar .rpl-Sidebar__root {
  height: calc(100vh - 117px);
  overflow-y: auto;
}

#rpl-content .rpl-MasterLayout__panel-content {
  overflow-y: auto;
  background-color: #e5e5e5;
}

#rpl-content .rpl-MasterLayout__panel-full-height {
  height: 100vh;
  background-color: #fff;
}

/** ------------------------------------------------  */

.rpl-layout__dark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #000;
  pointer-events: all;
  opacity: 0.6;
  z-index: 100;
}

.rpl-MasterLayout__drawer {
  display: flex;
  flex-direction: column;
  /** used to remove the white border in the drawer */
  border-right: none !important;
  max-width: 70%;
}

.rpl-MasterLayout__panel {
  flex-direction: column;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.rpl-MasterLayout__panel header {
  height: 38px;
}

.rpl-MasterLayout__panel h1 {
  margin: 0;
}

.rpl-MasterLayout__sidebar-header {
  background-color: #e86161;
  color: white;
  padding: 0px 22px;
  height: auto;
}

.rpl-MasterLayout__sidebar-header-logo {
  cursor: pointer;
}

.rpl-MasterLayout__sidebar-ripples {
  padding-top: 10px;
  padding-bottom: 15px;
}

.rpl-MasterLayout__identity {
  width: 100%;
  height: 52px;
  line-height: 52px;
  cursor: pointer;
}

.rpl-MasterLayout__identity svg {
  vertical-align: middle;
  float: right;
}

.rpl-MasterLayout__toggle-sidebar {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rpl-MasterLayout__toggle-sidebar svg {
  fill: #fff;
}

.rpl-MasterLayout__title {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.rpl-MasterLayout__identity-name {
  color: #ffffff;
  min-width: 96px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
}

.rpl-MasterLayout__identity-context-name {
  max-width: 89%;

  display: flex;
  align-items: center;
}

.rpl-MasterLayout__identity-context-name span {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: inline-block;
  max-width: 100%;
}

.rpl-MasterLayout__brand {
  background-color: #000000;
  height: 65px;
  display: flex;
  align-items: center;
}

.rpl-MasterLayout__brand-logo {
  margin-left: 25px;
  display: inline-block;
  cursor: pointer;
}

.rpl-MasterLayout__version-bar {
  display: flex;
  /*height: 65px;*/
  /*line-height: 65px;*/
  align-items: center;
}

.rpl-MasterLayout__version-bar-content {
  visibility: hidden;
  opacity: 0;
  color: white;

  font-style: italic;
  font-size: 80%;
  padding-left: 10px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.rpl-MasterLayout__version-bar-content.visible {
  visibility: visible;
  opacity: 1;
}

.rpl-MasterLayout__sidebar {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
  border-right: #e5e5e5;
}

.rpl-MasterLayout__sidebar .Sidebar__item a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rpl-MasterLayout__sidebar-shadow {
  background-color: black;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: visibility 0.5s ease-in, opacity 0.5s ease-in;
}

.rpl-MasterLayout__sidebar-shadow.visible {
  visibility: visible;
  opacity: 0.7;
}

.rpl-MasterLayout__identity-context {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.rpl-MasterLayout__identity-context-chevron {
  display: inline-block;
  height: 8px;
  width: 8px;
  position: relative;
  vertical-align: middle;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  margin: 0 8px;
  transform: rotate(45deg);
  transform-origin: center;
  transition-duration: 200ms;
}

.rpl-MasterLayout__identity-context.expanded
  .rpl-MasterLayout__identity-context-chevron {
  transform: rotate(135deg);
}

.rpl-MasterLayout__scopes {
  visibility: hidden;
  position: absolute;
  background-color: #ffffff;
  color: #828282;
  top: -20px;
  opacity: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: visibility 0.2s ease-in, top 0.2s ease-in, opacity 0.2s ease-in;
}

.rpl-MasterLayout__scopes.visible {
  visibility: visible;
  opacity: 1;
  top: 0;
}

.rpl-MasterLayout__scopes-buttons {
  text-align: right;
  padding: 10px;
}

/********* desktop *********/

.rpl-Break__desktop.rpl-MasterLayout__panel header {
  background-color: #000000;
  height: 65px;
}

.rpl-Break__desktop.rpl-MasterLayout__panel {
  left: 256px !important;
  /*height: 100vh;*/
  /*overflow: hidden;*/
}

.rpl-Break__desktop.rpl-MasterLayout__panel.no-sidebar {
  left: 0px !important;
}
.rpl-Break__desktop.rpl-MasterLayout__drawer {
  max-width: 256px;
}

.rpl-Break__desktop.rpl-MasterLayout__drawer.no-sidebar {
  height: 65px;
  overflow: hidden;
}

.rpl-Break__desktop
  .rpl-MasterLayout__sidebar-header
  .rpl-MasterLayout__identity-name {
  display: none;
}

.rpl-Break__desktop.rpl-MasterLayout__panel .rpl-MasterLayout__identity-name {
  margin-left: auto;
}

.rpl-Break__desktop .rpl-MasterLayout__scopes-buttons {
  display: none;
}

.ant-select-selection:hover {
  border-color: #e86161;
}

.ant-select-open .ant-select-selection {
  border-color: #e86161;
  box-shadow: 0px 0px #e86161;
}

/*.ant-btn-primary {*/
/*  color: #fff;*/
/*  background-color: #e86161;*/
/*  border-color: #e86161;*/
/*}*/

/*.ant-btn-primary:hover,*/
/*.ant-btn-primary:focus {*/
/*  color: #fff;*/
/*  background-color: #e86161b8;*/
/*  border-color: #e86161b8;*/
/*}*/

/*.ant-btn-background-ghost.ant-btn-primary:hover,*/
/*.ant-btn-background-ghost.ant-btn-primary:focus {*/
/*  color: #e86161;*/
/*  background-color: transparent;*/
/*  border-color: #e86161;*/
/*}*/

.ant-btn-background-ghost.ant-btn-primary {
  color: #e86161;
  background-color: transparent;
  border-color: #e86161;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-input:focus {
  border-color: #e86161;
}

.ant-input:hover {
  border-color: #e86161;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #e86161;
}

.ant-input:focus {
  box-shadow: none;
}

/*.ant-btn:not(.ant-btn-primary):not(.ant-btn-link):hover,*/
/*.ant-btn:not(.ant-btn-primary):not(.ant-btn-link):focus {*/
/*  color: #e86161;*/
/*  background: #e86161;*/
/*  border-color: #e86161;*/
/*}*/

button.ant-btn.ant-btn-link {
  color: #e86161;
  font-weight: 500;
}

button.ant-btn.ant-btn-link > span {
  text-decoration: underline;
}

button.ant-btn.ant-btn-sm.ant-btn-icon-only {
  padding: 0px 3px 0px 3px;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #e86161;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #e86161;
}

.ant-pagination-item-active a {
  color: #e86161;
}

.ant-pagination-item-active {
  border-color: #e86161;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #e86161;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #e86161;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #e86161;
  border-color: #e86161;
}

.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #e86161;
}

.ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-radio-button-wrapper {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.65);
  border-radius: 0px;
  flex: 1;
  text-align: center;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-radius: 0px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0px;
}

.ant-radio-button-wrapper:first-child {
  border: 1px solid rgba(0, 0, 0, 0.65);
  border-radius: 0px;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: inherit;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  border: none !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #e86161;
  border-color: #e86161;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #e86161;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #e86161;
  border-color: #e86161;
  /*-webkit-box-shadow: -1px 0 0 0 #e86161;*/
  /*box-shadow: -1px 0 0 0 #e86161;*/
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #e86161;
  border-color: #e86161;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #e86161 !important;
  opacity: 0.1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #e86161;
  border-color: #e86161;
  box-shadow: none;
  /*box-shadow: -1px 0 0 0 #e86161;*/
}

.ant-radio-button-wrapper:focus {
  outline: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: none;
}

.ant-slider-handle {
  width: 10px;
  height: 24px !important;
  margin-top: -10px;
  background-color: #e86161 !important;
  border: solid 2px #e86161 !important;
  border-radius: 10px;
}

.ant-slider-handle:focus {
  box-shadow: none;
}

.ant-spin-dot-item {
  background: #e86161;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e86161;
  border-color: #e86161;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e86161;
}

.ant-checkbox-checked::after {
  border: 1px solid #e86161;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #e86161;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: #e86161;
}

.ant-select-dropdown-menu-item {
  min-height: 2em;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  color: #e86161;
  border-color: #e86161;
  background: transparent;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked):first-child {
  color: rgb(0, 0, 0, 0.65);
  border-color: rgb(0, 0, 0, 0.65);
  background: transparent;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked):hover {
  background: transparent;
  color: rgb(0, 0, 0, 0.65);
  border-color: rgb(0, 0, 0, 0.65);
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
  color: rgb(0, 0, 0, 0.65);
}

.ant-table-placeholder {
  min-height: 100px;
}

.rpl-MasterLayout__store-button {
  background-color: #e86161;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 6px 30px;
  letter-spacing: 1px;
  font-size: 16px;
  margin-right: 15px;
  line-height: 1;
  height: 38px;
}

.rpl-MasterLayout__store-button:hover {
  color: #e86161;
  background-color: #e7e7e9;
}

@media only screen and (max-width: 960px) {
  .ant-layout-header {
    min-width: 490px;
    background: #e86161;
  }

  .ant-layout-header .rpl-MasterLayout__store-button {
    margin-right: 0;
  }
}

@media only screen and (max-width: 570px) {
  #rpl-header {
    column-gap: unset;
  }

  .ant-layout-header .rpl-MasterLayout__version-bar,
  .ant-layout-header .rpl-MasterLayout__identity-name,
  .ant-layout-header .rpl-MasterLayout__store-button {
    width: 100%;
    margin: 0;
  }
}

.rpl-WarningBanner {
  width: 100%;
  padding: 10px;
  min-height: 53px;
  height: fit-content;
  background: #e86161;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.rpl-WarningBanner-container {
  width: 100%;
  padding: 5px 5px 0px 5px;
}

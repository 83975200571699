.rpl-ScopePicker__body {
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #bdbdbd;
}

.rpl-ScopePicker__group {
  border-bottom: 1px solid #bdbdbd;
  padding: 10px 0;
}

.rpl-ScopePicker__body :last-child {
  border: none;
}

.rpl-ScopePicker__item {
  display: block;
  padding: 5px 10px;
  color: #828282;
  text-decoration: none;
  cursor: pointer;
}

.rpl-ScopePicker__item:hover {
  color: #e86161;
}

.rpl-ScopePicker__search {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding-right: 8px;
}

.rpl-ScopePicker__search-input {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  font-size: 14px;
  color: #828282;
}

.rpl-ScopePicker__search-input::placeholder {
  color: #bdbdbd;
}

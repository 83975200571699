.rpl-LoaderPage {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
}

.rpl-LoaderPage .rpl-Spinner {
  position: relative;
  top: 50%;
  margin: -50px;
}

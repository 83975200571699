:root {
  /* todo: seems --color-primary is occupied by 3rd party lib */
  --color-rpl-primary: #e86161;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.ant-layout {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.ant-layout-content {
  color: rgba(0, 0, 0, 0.65);
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: 55px;
  line-height: 55px;
  padding: 0 10px;
}

.camera-view-container {
  position: fixed;
  bottom: 0;
  left: 30px;
  padding: 20px;
  width: 300px;
  border-radius: 10px 10px 0 0;
  border: 1px solid gray;
  border-style: solid solid none solid;
  background-color: white;
  z-index: 999;
}

/**
Modal's Style
 */
.modal-content {
  border-radius: 10px;
}

.close:focus {
  outline: none;
}

.input-error {
  border-color: #dd4b39;
}

/**
fulfillment info-boxes
 */

.info-box-content > input {
  border-color: transparent;
  background-color: transparent;
  font-weight: bold;
  font-size: 16px;
}

/**
batch info-boxes
 */

.info-box {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

/**
form
 */

.form-group.has-error .input-group .input-group-addon {
  border-color: #dd4b39;
}

/* Radio */
.ant-radio input[type='radio'] {
  margin: 0;
}

.ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: transparent;
}

.ant-radio .ant-radio-inner:after {
  background-color: #e86161;
}

/*
header dropdown
 */
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  color: #f4f4f4;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > .user-name {
  font-size: 18px;
}

/*
new batch
 */

.thumbnail.new-batch {
  min-height: 168px;
}

/*
tags
 */

.tags-container {
  border-bottom: 1px solid #d2d6de;
  border-right: 1px solid #d2d6de;
  border-left: 1px solid #d2d6de;
  padding: 10px 5px;
}

.tag {
  display: inline-block;
  margin: 0 5px;
  cursor: default;
}

.tag .remove {
  color: #d5dce0;
  margin-left: 5px;
  cursor: pointer;
}

.tag .remove:hover {
  color: #fff;
}

/*
drag n drop content
 */

.dnd-thumbnail-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
  box-sizing: border-box;
}

.dnd-thumbnail-preview {
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.thumbnail-ripple {
  height: inherit;
  width: inherit;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.thumbnail-ripple.checked {
  border: 2px solid red;
}

input[type='file']#select-image-input {
  width: 83px;
  margin-left: 10px;
  display: inline-block;
}

input[type='file']#select-image-input:focus {
  outline: none;
}

#editingZone {
  height: 300px;
  border: 1px dashed red;
  overflow: hidden;
  padding: 0;
  margin-bottom: 20px;
}

.html2canvas-container {
  width: 1600px !important;
  height: 2050px !important;
}

/*
DatePicker style
 */

.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::after,
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  border-width: 2px 0 0 2px !important;
  border-color: black !important;
}

.ant-picker-now-btn,
.ant-picker-now-btn:hover,
.ant-picker-now-btn:active,
.ant-picker-now-btn:focus {
  color: #e86161;
}

/* bs4 flex utils */
.d-flex,
.flex-row {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
/* color helpers */
.bg-white {
  background-color: #fff;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-orange {
  color: #e86161;
}
.text-gray {
  color: #828282;
}
.text-gray-1 {
  color: #bdbdbd;
}
/* antd table */
.ant-table-column-title {
  font-weight: bold;
}
/* text helpers
------------------- */
.text-center {
  text-align: center;
}
/* font size helpers
------------------- */
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
/* -------------------------------
Spacing helpers
---------------------------------- */
/* Margin */
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mt-1 {
  margin-top: 8px;
}
.mr-1 {
  margin-right: 8px;
}
.mb-1 {
  margin-bottom: 8px;
}
.ml-1 {
  margin-left: 8px;
}
.mt-2 {
  margin-top: 12px;
}
.mr-2 {
  margin-right: 12px;
}
.mb-2 {
  margin-bottom: 12px;
}
.ml-2 {
  margin-left: 12px;
}
.mt-3 {
  margin-top: 24px;
}
.mr-3 {
  margin-right: 24px;
}
.mb-3 {
  margin-bottom: 24px;
}
.ml-3 {
  margin-left: 24px;
}
.mt-4 {
  margin-top: 48px;
}
.mr-4 {
  margin-right: 48px;
}
.mb-4 {
  margin-bottom: 48px;
}
.ml-4 {
  margin-left: 48px;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}

.rpl-ProgressBar {
  width: 384px;
  height: 14px;
}

.rpl-ProgressBar__container {
  width: 100%;
  height: 100%;
  background: #bdbdbd;
  position: relative;
}

.rpl-ProgressBar__progress {
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background: #e86161;
  transition: width 0.2s ease;
}

.rpl-ProgressBar__preparing_message {
  position: absolute;
  top: 0;
  left: 0;

  color: #e86161;
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  user-select: none;

  margin-left: 8px;
}

.rpl-NoScopePage {
  background: #fff;
  min-height: 100%;
  padding: 80px;
}

.NoScopePage__inner {
  max-width: 1160px;
  margin: 0 auto;
}

.rpl-Break__tablet .rpl-NoScopePage {
  padding: 30px;
}

.rpl-NoScopePage__image {
  width: 34%;
  float: right;
  min-height: 500px;
  background-image: url('../images/ripple-signup.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

.rpl-Break__tablet .rpl-NoScopePage__image {
  display: none;
}

.rpl-NoScopePage__content {
  width: 60%;
}

.rpl-Break__tablet .rpl-NoScopePage__content {
  width: 100%;
}

.rpl-NoScopePage__content h2 {
  text-transform: uppercase;
  font-size: 3.6em;
  letter-spacing: -2px;
  line-height: 1.4em;
}

.rpl-NoScopePage__content p {
  font-size: 1.8em;
  font-weight: bold;
  color: #828282;
}

.rpl-NoScopePage__content p.rpl-NoScopePage__red {
  color: #e86161;
}

.rpl-NoScopePage__content p.rpl-NoScopePage__or {
  font-weight: normal;
  text-align: center;
}

.rpl-NoScopePage__content p.rpl-NoScopePage__button {
  text-align: center;
  font-size: 1.2em;
}

.rpl-NoScopePage__content p.rpl-NoScopePage__button button {
  border: none;
  background-color: #e86161;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  padding: 7px 30px 3px 30px;
  border-radius: 20px;
}

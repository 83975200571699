.rpl-HomePage {
}

.rpl-HomePage__container {
  width: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 48px;
}

.rpl-HomePage__jumbotron {
  height: 600px;
  width: 100%;
  background: #e7e7e9 url('../img/homepage-background.png') no-repeat bottom;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rpl-HomePage__jumbotron p {
  width: 90%;
  color: #e25050;
  font-size: 48px;
  line-height: 64px;
  font-weight: 400;
  text-transform: uppercase;
}

.rpl-HomePage__pane {
  width: 90%;
  margin-top: 36px;
}

.rpl-HomePage__pane .title {
  color: #828282;
  font-size: 28px;
  font-weight: 600;
}

.rpl-HomePage__pane-container {
}

.rpl-HomePage__pane-container-content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rpl-HomePage__content-box {
  height: 340px;
  width: 30%;
  max-width: 440px;

  background: #e96e6e;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rpl-HomePage__content-box.big {
  width: 100%;
  max-width: none;
}

.rpl-HomePage__content-box-title {
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.rpl-HomePage__content-box-children {
  margin: 24px 0 24px 0;

  height: 120px;
  width: 90%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rpl-HomePage__content-box-action {
  width: 90%;
  max-width: 260px;
  padding: 10px;

  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;

  border: 2px solid white;
}

.rpl-HomePage__content-box-devices-item {
  width: 80px;
  height: 80px;

  margin: 0 2px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.rpl-HomePage__content-box-devices-item img {
  width: auto;
  height: 70px;
}

.rpl-HomePage__content-box-ripples-item {
  width: 70px;
  height: 70px;

  margin: 0 2px;
}

.rpl-HomePage__content-box-ripples-item.big {
  width: 110px;
  height: 110px;

  margin: 0 2px;
}

.rpl-HomePage__content-box-ripples-item img {
  width: 100%;
  height: 100%;
}

.rpl-HomePage__content-box-channels-item {
  width: 70px;
  height: 70px;

  margin: 0 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rpl-HomePage__content-box-channels-item img {
  width: 100%;
  height: auto;
}

.rpl-HomePage__container-content-list {
  height: 300px;
  width: 100%;
  background: white;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rpl-HomePage__content-list {
  width: 90%;

  display: flex;
  flex-direction: column;
}

.rpl-HomePage__content-list-title {
  width: 100%;

  color: #e25050;
  font-size: 16px;
  font-weight: 600;

  text-transform: uppercase;
}

.rpl-HomePage__content-list-children {
  width: 100%;
  height: 140px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.rpl-HomePage__content-list-action {
  width: 280px;
  padding: 10px;

  background: #e96e6e;
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;

  align-self: center;
}

.rpl-HomePage__table {
  width: 100%;
  border-spacing: 0px;
}

.rpl-HomePage__table-headers {
  width: 100%;
  height: 48px;

  background: #f2f2f2;
}

.rpl-HomePage__table-headers th {
  text-align: start;
  color: #4f4f4f;

  font-size: 16px;
  font-weight: 600;
}

.rpl-HomePage__table-headers th:first-child {
  padding-left: 64px;
  color: #e86161;
}

.rpl-HomePage__table tr td {
  padding-top: 16px;
}

.rpl-HomePage__table tr td > span {
  color: #7a7a7a;

  font-size: 16px;
  font-weight: 400;
}

.rpl-HomePage__table-first-cell {
  padding-left: 64px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.rpl-HomePage__table-first-cell-content {
  margin-left: 16px;
}

.rpl-HomePage__table-locations-item-name {
  color: #4f4f4f;

  font-size: 16px;
  font-weight: 600;
}

.rpl-HomePage__table-locations-item-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin-top: 8px;
}

.rpl-HomePage__table-locations-item-footer-chip {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
}

.rpl-HomePage__table-users-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rpl-HomePage__table-users-item-name {
  color: #7a7a7a;

  font-size: 16px;
  font-weight: 400;
}

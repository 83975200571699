.rpl-Page__wrapper {
  background: #e5e5e5;
  background-size: cover;
  /*flex: 1;*/
  /*display: flex;*/
  position: relative;
  /*flex-wrap: wrap;*/
}

.rpl-Page__title {
  display: none;
}

.rpl-Page__breadcrumbs-container {
  display: inline;
}

.rpl-Page__breadcrumbs {
  width: 100%;
  height: 48px;
  margin: 16px 0px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rpl-Page__content-container {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
  height: calc(100vh - 65px);
}

.rpl-Page__content-container__fixed {
  height: calc(100vh - 117px);
  padding-bottom: 0;
}

.rpl-Page__content-container-no-scrolling {
  overflow-y: hidden;
}

.rpl-Page__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rpl-FooterActions {
  width: 200px;
  height: 38px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rpl-FooterActions__drawer {
  position: fixed;
  width: 100%;
  height: 26px;
  display: flex;
  bottom: 0;
  left: 0;
  visibility: hidden;
  transition: visibility 0.3s, transform 0.3s, opacity 0.3s;
  transform: translate(0, 10px);
  opacity: 0;
  z-index: 9;
}

.rpl-FooterActions__drawer-button {
  font-size: 12px;
  min-width: 90px;
  /*text-transform: uppercase;*/
}

/*.rpl-FooterActions__drawer-button {*/
/*  !*flex: 1;*!*/
/*  height: 100%;*/
/*  line-height: 26px;*/
/*  background-color: #aeaeae;*/
/*  border-radius: 0;*/
/*  font-size: 12px;*/
/*  color: #fff;*/
/*}*/

/*.rpl-FooterActions__drawer-button {*/
/*  color: #ffffff !important;*/
/*}*/

/*.rpl-FooterActions__drawer-button.primary,*/
/*.rpl-FooterActions__drawer-button.primary:hover {*/
/*  background-color: #e96362 !important;*/
/*}*/

/*.rpl-FooterActions__drawer-button.disabled,*/
/*.rpl-FooterActions__drawer-button.disabled:hover {*/
/*  background-color: #d7d7d7 !important;*/
/*  color: #c4c4c4 !important;*/
/*}*/

/*.rpl-FooterActions__drawer-button.primary.disabled {*/
/*  background-color: #d7d7d7;*/
/*  color: #c4c4c4 !important;*/
/*}*/

.rpl-FooterActions__error {
  width: calc(100% - 180px);
  display: flex;
  justify-content: flex-end;
  padding-right: 21px;
  align-items: center;
  color: #e86161;
}

.rpl-Page__wrapper-action {
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.rpl-Page__footer-actions-container {
}
.rpl-Page__footer-action {
}
.rpl-MainAction-Button_ {
  background: #ffffff;
  border: 1px solid #e86161;
  box-sizing: border-box;
  color: #e86161;
  border-radius: 2px;

  min-width: 140px;
  height: 30px;
  padding: 0 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  line-height: normal;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
}

.rpl-MainAction-Button_disabled {
  background: #ffffff;
  border: 1px solid #868383;
  box-sizing: border-box;
  color: #e86161;
  border-radius: 2px;

  min-width: 140px;
  height: 30px;
  padding: 0 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  line-height: normal;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;

  cursor: not-allowed;
}

.rpl-MainAction {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  margin-top: -53px;
  padding-bottom: 20px;
}

/********* desktop *********/

.rpl-Break__desktop .rpl-Page__wrapper {
  /*height: 100%;*/
  width: 100%;
  background: #e5e5e5;
  background-size: cover;
}

.rpl-Break__desktop .rpl-FooterActions {
  position: relative;
  float: right;
  margin-top: 8px;
}

.rpl-Break__desktop .rpl-Page__content {
  max-width: 1200px;
  margin: auto;
  padding: 0 32px;
}

.rpl-Break__desktop .rpl-Page__with-no-overflow .rpl-Page__content {
  height: 100%;
}

.rpl-Break__desktop .rpl-Page__title {
  display: inline;
  height: 48px;
  line-height: 48px;
  width: 100%;
  color: #7a7a7a;
  font-size: 13px;
  font-weight: 600;
}

.rpl-Page__stripe-mark {
  width: 100%;
  height: 22px;
  background-color: #d54848;
}

.rpl-Page__stripe-mark.link {
  background-color: #d54848;
  background-repeat: repeat-x;
  background-position: center center;
  background-size: auto 50%;
  background-repeat: space no-repeat; /* for supporting browsers */
}

.rpl-Page__stripe-mark-glyph-link {
  width: 100%;
  height: 100%;
  background-repeat: space no-repeat; /* for supporting browsers */
  background-position: center;
  background-size: auto 50%;
  opacity: 0.6;
}

.rpl-Page__wide .rpl-Page__content {
  max-width: inherit;
}

.rpl-Sidebar__root {
  flex: 1;
  padding-left: 20px;
  padding-bottom: 36px;
  background-color: #ffffff;
  overflow-y: auto;
}

.rpl-Sidebar__item a:active,
.rpl-Sidebar__item a:hover,
.rpl-Sidebar__item a:visited,
.rpl-Sidebar__item a {
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: pre;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.rpl-Sidebar__item a.rpl-Sidebar__item-highlighted {
  color: #e86161;
  background-color: #e5e5e5;
  border-color: #e86161;
  font-weight: 600;
}

.rpl-Sidebar__item a:hover {
  color: #828282;
  border-color: #828282;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.rpl-Sidebar__item a i {
  display: inline-block;
  width: 28px;
  height: 28px;
}

.rpl-Sidebar__category {
  margin: 22px 0px 16px 0px;
}

.rpl-Sidebar__category span {
  color: #000000;
  line-height: 25px;
  font-size: 16px;
  font-weight: 300;
}

.rpl-Sidebar__item a {
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  color: #7a7a7a;
  border-left: 8px solid #e5e5e5;
  text-indent: 8px;
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.rpl-BreadcrumbItem {
}

.rpl-BreadcrumbItem__container {
  height: 100%;
  padding: 2px;
}

.rpl-BreadcrumbItem__container span {
  color: #7a7a7a;
  font-size: 18px;
  line-height: 19px;
  font-weight: 400;
}

.rpl-BreadcrumbItem__container.current span {
  color: #7a7a7a;
  font-size: 18px;
  line-height: 19px;
  font-weight: 600;
}

.rpl-BreadcrumbItem__container.enabled {
  cursor: pointer;
}

.rpl-BreadcrumbItem__container.disabled {
  cursor: text;
}

.rpl-BreadcrumbItem__container-click-area {
  height: 100%;
}

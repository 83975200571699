.rpl-RippleMakerChip {
}

.rpl-RippleMakerChip__container {
  display: inline-flex;
  align-items: center;
  padding: 1px 4px;
  text-indent: 4px;
  background-color: #eaeaea;
  border-radius: 9px;
  border: 1px solid #eaeaea;
}

.rpl-RippleMakerChip__container:hover {
  border: 1px solid #4f4f4f;
}

.rpl-RippleMakerChip__text-serial {
  color: #828282;
  font-size: 12px;
  font-weight: 400;
}

.ripples-logo .round {
  fill: #dc4e58;
}
.ripples-logo .lines {
  fill: #fff;
}

.ripples-logo.white .round {
  fill: #fff;
}
.ripples-logo.white .lines {
  fill: #dc4e58;
}

@keyframes rpl-Spinner-ripple {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes rpl-Spinner-core {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(0.5);
  }
}

.rpl-Spinner div,
.rpl-Spinner {
  width: 50px;
  height: 50px;
}

.rpl-Spinner.small div,
.rpl-Spinner.small {
  width: 28px;
  height: 28px;
}

.rpl-Spinner.large div,
.rpl-Spinner.large {
  width: 100px;
  height: 100px;
}

.rpl-Spinner {
  position: relative;
  display: inline-block;
}

.rpl-Spinner div {
  position: absolute;
  border-radius: 100%;
  border: 2px solid #d04a5a;
  animation: rpl-Spinner-ripple 1.6s 0s infinite
    cubic-bezier(0.21, 0.53, 0.56, 0.8);
}

.rpl-Spinner div:nth-child(2) {
  animation-delay: -0.8s;
}

.rpl-Spinner div:nth-child(3) {
  animation-delay: -0.4s;
}

/* the core circle in the middle */
.rpl-Spinner div:nth-child(1) {
  border-color: transparent;
  background-color: #d04a5a;
  animation: rpl-Spinner-core 0.8s 0s infinite alternate;
}
.rpl-Spinner.large div:nth-child(1) {
  border-color: transparent;
  background-color: #d04a5a;
  animation: none;
  transform: scale(0.5);
  z-index: 1;
}

.rpl_LoadingOverlay {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
}

.rpl_LoadingOverlay .rpl-Spinner {
  position: absolute;
  z-index: 99999;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.rpl_LoadingOverlay .rpl-ProgressBar {
  position: absolute;
  z-index: 99999;
  margin: 25% auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.rpl_LoadingOverlay_mask {
  background-color: white;
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 10;
  transition: visibility 0.2s ease, opacity 0.2s ease;
}

.rpl_LoadingOverlay_mask.visible {
  opacity: 0.6;
  visibility: visible;
}

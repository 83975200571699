.rpl-ActionBar {
  position: relative;
  height: 38px;
  line-height: 38px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  -webkit-box-shadow: 0 1px 3px 0 rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 0 1px 3px 0 rgba(94, 94, 94, 0.5);
  box-shadow: 0 1px 3px 0 rgba(94, 94, 94, 0.5);
  z-index: 1;
  flex-shrink: 0;
  background-color: #ffffff;
}

.rpl-ActionBar .rpl-ActionBar__inner {
  max-width: 1200px;
  margin: auto;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.ActionBar__warningText_wrapper {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;

  color: #282828;
}

.rpl-ActionBar__warningText_text {
  margin-left: 10px;
}
.rpl-ActionBar__warningText_icon {
  border: 2px solid #282828;
  box-sizing: border-box;
  border-radius: 50%;
}

.rpl-ActionBar__search {
  display: flex;
  align-items: center;
}

.rpl-ActionBar__actions {
  display: flex;
  justify-content: flex-end;
}

.rpl-Action__drawer-action {
  position: relative;
  height: 52px;
  width: 52px;
  line-height: 52px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rpl-Action__drawer-action .rpl-Action__drawer-action-tooltip {
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  bottom: -8px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 8px;
}

.rpl-Action__drawer-action:hover .rpl-Action__drawer-action-tooltip {
  opacity: 1;
}

.rpl-Action__drawer-action-tooltip {
  pointer-events: none;
  width: max-content;
}

.rpl-Action__drawer-action:hover svg path {
  fill: #e86161 !important;
}

.rpl-Action__drawer-action-disabled:hover svg path {
  fill: #616161 !important;
}

.rpl-Action__drawer-action-selected svg path {
  fill: #e86161 !important;
}

.rpl-Action__drawer-action svg path,
.rpl-Action__drawer-action-disabled svg path,
.rpl-Action__drawer-action .rpl-Action__drawer-action-tooltip {
  transition: 200ms;
  transition-timing-function: ease-in-out;
}

.rpl-ActionBar__menu .rpl-Action__drawer-action {
  border: none;
}

.rpl-Action__drawer-action-disabled {
  color: #8c8c8c;
  cursor: no-drop;
}

.rpl-SearchInput__container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding-right: 8px;
  min-width: 285px;
  height: 20px;
}

.rpl-SearchInput__search-input {
  flex: 1;
  border: 0;
  color: #828282;
  outline: 0;
  background-color: transparent;
  font-size: 13px;
  text-indent: 8px;
  min-width: 280px;
}

.rpl-SearchInput__search-input::placeholder {
  color: #bdbdbd;
}

.rpl-SearchInput__search-clear {
  height: 18px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.rpl-SearchInput__search-clear:hover {
  cursor: pointer;
  color: #e86161;
}

.rpl-SearchInput__NextAndPrev_container {
  display: flex;
  margin-left: 15px;
  justify-content: space-between;
  min-width: 40px;
}

.rpl-SearchInput__NextAndPrev_button:hover {
  cursor: pointer;
  color: #e86161;
}

/********* desktop *********/

.rpl-Break__desktop .rpl-ActionBar {
  height: 52px;
  line-height: 52px;
}

.rpl-Break__desktop .rpl-Action__drawer-action {
  position: relative;
  width: 52px;
  height: 52px;
  line-height: 52px;
}
